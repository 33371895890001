import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { get } from 'lodash'
import SEO from '../components/seo'
import Button from 'components/button/Button'
import '../layout/servicePages.less'
import LayoutNew from 'layout/LayoutNew'

const NotFoundPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allSitePage {
          edges {
            node {
              path
              context {
                slug
              }
            }
          }
        }
        contentfulWebsiteConfiguration {
          title404
          body404 {
            raw
          }
        }
      }
    `}
    render={(data: any) => {
      const allPages = data.allSitePage.edges
      const this404Page = data.contentfulWebsiteConfiguration

      let slug: string

      if (typeof window !== `undefined`) {
        slug = location.pathname.replace(/\//g, '')
        let url = allPages.filter((link) => link.node.context && link.node.context.slug == slug)
        url.length > 0 && navigate(url[0].node.path)
      }

      return (
        <LayoutNew>
          <SEO description="" title="404: Not found" />
          <section className="Page-container error-page">
            <div className="error-page--container">
              <p className="error-page--code">404</p>
              <h2>{get(this404Page, 'title404')}</h2>
              <p className="error-page--message">
                Sidan du söker verkar inte finnas här. Kolla så att du stavat rätt och försök igen.
              </p>
              <Button to="#" className="button button--normal orange">
                Till startsidan
              </Button>
            </div>
          </section>
        </LayoutNew>
      )
    }}
  />
)

export default NotFoundPage
